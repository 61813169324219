body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: #fff !important;
  @media (min-width: 600px) {
    overflow: hidden;
  }
}

strong,
em {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  width: 100%;
  overflow: auto;
  min-height: calc(100vh - 100px);
  @media (min-width: 600px) {
    height: calc(100vh - 32px);
  }
}

/* react leaflet */
.leaflet-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.leaflet-control-attribution {
  display: none;
}

/* react-leaflet-draw */
.sr-only {
  display: none;
}

/**
 * PDF Hacks
 *
 * It would be nice to get a copy of the PDF which does not have these issues.
 * This will have to suffice for the moment.
 */
.react-pdf__Document rect {
  fill: #fff;
}

.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__textContent>span {
  color: #1a428a;
}

.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__textContent>span:last-of-type {
  display: none;
  visibility: hidden;
}

.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__svg [clip-path="url(#clippath2)"],
.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__svg [clip-path="url(#clippath4)"],
.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__svg [clip-path="url(#clippath164)"] {
  display: none;
  visibility: hidden;
}

.react-pdf__Document [data-page-number="1"]>.react-pdf__Page__svg [clip-path="url(#clippath165)"] path {
  fill: #fff;
}
